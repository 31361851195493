import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// analytics.service.ts (final working version)
@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initialize();
      this.trackPageViews();
    }
  }

  private initialize() {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments) };

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingId}`;
    document.head.appendChild(script);

    window.gtag('js', new Date());
    window.gtag('config', environment.googleAnalyticsTrackingId, {
      send_page_view: false,
      debug_mode: true
    });
  }

  private trackPageViews(): void {
	this.router.events
	  .pipe(
		filter((event): event is NavigationEnd => event instanceof NavigationEnd)
	  )
	  .subscribe(event => {
		if (typeof window !== 'undefined' && window.gtag) {
		  window.gtag('config', environment.googleAnalyticsTrackingId, {
			page_path: event.urlAfterRedirects,
			page_title: document.title
		  });
		}
	  });
  }
}
